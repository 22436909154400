.whatsapp-button {
  width: 130px;
  height: 40px;
  position: fixed;
  bottom: 50px;
  right: 20px;
  /* background-color: #07923a; */
  background-color: #ffd301;
  padding: 8px;
  border-radius: 30px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  animation: whatsAppBtn 3s infinite;
  color: #fff;
}

.whatsapp-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: #fff;
  /* font-size: 24px; */
}
.contact-text {
  color: #000;
  font-weight: 500;
  margin-right: 5px;
}
.whatsapp-icon {
  font-size: 20px;
  color: #000;
}

@keyframes whatsAppBtn {
  0% {
    box-shadow: 3px 2px 10px 2px #ffa505;
  }
  50% {
    transform: scaleX(1.1);
    /* transform: scaleY(1.1); */
  }
  100% {
    box-shadow: 1px 1px 10px 2px rgb(40, 65, 2);
  }
}
