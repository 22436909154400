.hired-companies-container {
  padding: 2rem;
  display: flex;
  flex-direction: column;
}
.hired-company-heading {
  text-align: center;
  align-self: center;
  font-family: sofia-pro, sans-serif;
  font-size: 38px;
  letter-spacing: 1px;
  line-height: 68px;
  color: #183b56;
}
@media screen and (max-width: 767px) {
  .hired-company-heading {
    font-size: 22px;
    line-height: 38px;
  }
}
.company-count {
  color: #1565d8;
}
.company-name {
  color: #ffa505;
  /* color: #ffb805; */
}
.company-logos-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.company-logo {
  width: 180px;
  /* margin: 1rem; */
}

@media screen and (max-width: 767px) {
  .company-logo {
    width: 80px;
    margin-bottom: 2rem;
  }
}
.callback-btn {
  /* margin-left: auto; */
  margin-top: 5rem;
  align-self: center;
}
@media screen and (max-width: 768px) {
  .callback-btn {
    margin-top: 3rem;
  }
}
.hired-request-btn {
  background-color: #ffd301;
  border: none;
  height: 48px;
  /* width: 100%; */
  border-radius: 8px;
  color: #000;
  font-weight: 600;
  letter-spacing: 1px;
  font-family: sofia-pro, sans-serif;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.calling-icon {
  margin-right: 8px;
}
.whatsapp-btn {
  text-decoration: none;
  color: #000;
}
