.footer-section {
  background-color: #0d2436;
  padding-top: 50px;
  padding-bottom: 50px;
  padding: 5rem;
}
.footer-first-part {
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 768px) {
  .footer-section {
    padding: 1rem;
  }
  .footer-first-part {
    flex-direction: column;
    /* align-items: center; */
  }
}
.company-logo {
  width: 13%;
  border-radius: 10px;
}
.footer-first-part-heading {
  color: #5a7184;
  font-weight: 700;
  font-family: sofia-pro, sans-serif;
  font-size: 20px;
}
.phone-number-section {
  display: flex;
  align-items: center;
}
.whatsup-icon {
  color: #fff;
  background-color: green;
  border-radius: 30%;
  font-size: 20px;
  margin-right: 8px;
}
.whatsup-number {
  color: #5a7184;
  font-weight: 600;
  margin: 0px;
  padding: 0px;
}
.email-section {
  display: flex;
  align-items: center;
}
.email-icon {
  color: #fff;
  background-color: #0072c6;
  border-radius: 20%;
  font-size: 20px;
  margin-right: 8px;
}
.company-eamil {
  color: #5a7184;
  font-weight: 600;
}
.quick-links-container {
  display: flex;
  margin: 0px;
}
.links-container {
  list-style-type: none;
  margin: 0;
  margin-right: 3rem;
  padding: 0;
}
.footer-link-item {
  color: #5a7184;
  font-weight: 500;
  padding: 0.5rem 0rem;
  padding-right: 1rem;
}
.footer-link {
  color: #5a7184;
  font-weight: 600;
  font-family: sofia-pro, sans-serif;
  font-size: 15px;
  text-decoration: none;
  cursor: pointer;
}
.footer-link:hover {
  color: #fff;
}
.payments-icons-container-one {
  margin-bottom: 1rem;
}
.payment-icon {
  margin-right: 1rem;
  width: 50px;
  background-color: #fff;
  padding: 1px;
  border-radius: 3px;
}
.location-container {
  display: flex;
  margin: 2rem 0rem;
}
.location-icon {
  color: #5a7184;
  margin-right: 8px;
}
.address {
  color: #5a7184;
  font-weight: 500;
  font-family: sofia-pro, sans-serif;
  font-size: 14px;
  margin: 0;
  line-height: 22px;
}
.row {
  border: 1px solid #5a7184;
  margin: 3rem 0rem;
  opacity: 0.2;
}
.footer-middle-part {
  display: flex;
}
@media screen and (max-width: 768px) {
  .footer-middle-part {
    flex-direction: column;
  }
}
.social-media-container {
  margin-right: 10rem;
}
.social-media-icon {
  width: 30px;
  border-radius: 30%;
  margin-right: 1rem;
}
@media screen and (max-width: 768px) {
  .social-media-icon {
    width: 25px;
  }
}
/* .conditions-policies-section {
  opacity: 0.8;
}

@media screen and (max-width: 768px) {
  .conditions-policies-section {
    display: flex;
  }
}
.conditions-policies-links-container {
  display: flex;
  list-style-type: none;
  padding: 0px;
}

@media screen and (max-width: 768px) {
  .conditions-policies-links-container {
    flex-direction: column;
  }
}

.conditions-policies-link-item {
  margin-right: 2rem;
}
.conditions-policies-link {
  color: #5a7184;
  font-family: sofia-pro, sans-serif;
  font-size: 14px;
  margin: 0;
  letter-spacing: 1px;
  line-height: 22px;
  text-decoration: none;
  font-weight: 500;
}
.conditions-policies-link:hover {
  color: #fff;
} */
.copy-rights-container {
  color: #fff;
  text-align: center;
}
