.learn-alumni-container {
  text-align: center;
  padding: 2rem 1rem;
}
.alumni-heading {
  color: #183b56;
  font-weight: 500;
  margin-bottom: 0px;
  padding-bottom: 0px;
  /* border: 1px solid red; */
}
.alumni-logos-container {
  margin-top: 1.5rem;
}
.alumni-image {
  width: 120px;
  margin: 1rem 2rem;
  padding: 0px;
}
@media screen and (max-width: 767px) {
  .alumni-image {
    width: 80px;
  }
}
.and-many-more {
  color: #56626b;
  font-weight: 500;
  /* margin: 0px; */
  /* padding: 0px; */
  /* border: 1px solid green; */
}
