.program-nav-header {
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 768px) {
  .program-nav-header {
    flex-direction: column;
    align-items: center;
    border-bottom-style: none;
  }
}
.program-nav-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1110px;
  padding-bottom: 10px;
}
@media screen and (max-width: 768px) {
  .program-nav-content {
    flex-direction: column;
    width: 90%;
  }
}

.program-nav-bar-mobile-logo-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  /* border: 1px solid red; */
}

@media screen and (min-width: 768px) {
  .program-nav-bar-mobile-logo-container {
    display: none;
  }
}

.program-nav-bar-large-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1110px;
  /* border: 1px solid red; */
}

@media screen and (max-width: 767px) {
  .program-nav-bar-large-container {
    display: none;
  }
}

.program-website-logo {
  width: 80px;
  height: 50px;
  margin-top: 5px;
  border-radius: 10%;
}

@media screen and (min-width: 768px) {
  .program-website-logo {
    width: 100px;
    height: 60px;
  }
}

.program-nav-menu {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-self: center;
  flex: 1;
  list-style-type: none;
  margin-top: 0px;
  margin-bottom: 0px;
}

@media screen and (max-width: 767px) {
  .program-nav-menu {
    display: none;
  }
}
.program-nav-menu-mobile {
  width: 90%;
}

@media screen and (min-width: 768px) {
  .program-nav-menu-mobile {
    display: none;
  }
}

.program-nav-menu-list-mobile {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex: 1;
  list-style-type: none;
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 0px;
}

.program-nav-menu-item {
  font-family: "Roboto";
  text-decoration: none;
  margin: 10px;
}

.program-nav-link {
  text-decoration: none;
  color: #333;
  font-size: 16px;
  font-weight: 600;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
/* .home-link:active {
    color: #0082f3;
  } */
.program-nav-link:hover {
  color: #0082f3;
}

@media screen and (max-width: 767px) {
  .program-nav-link {
    font-size: 14px;
  }
}

.program-nav-menu-item-mobile {
  padding: 5px;
  cursor: pointer;
}

/* .nav-mobile-btn-container {
    display: flex;
    justify-content: center;
    width: 25%;
  } */
.program-mobile-nav-bar-btn-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
}
.program-nav-mobile-btn {
  border: none;
  padding: 0px;
  background: transparent;
  cursor: pointer;
  outline: none;
  color: #333;
  font-size: 20px;
  margin-right: 2rem;
}
.program-join-now-btn {
  background: none;
  border: none;
}
@media screen and (min-width: 768px) {
  .program-nav-mobile-btn {
    display: none;
  }
  .program-join-now-btn {
    display: none;
  }
}
.program-nav-bar-image {
  width: 24px;
}
