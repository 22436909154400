.trainers-container {
  padding: 3rem 4rem;
  background-color: #f8fafc;
  width: 100%;
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 767px) {
  .trainers-container {
    padding: 0.5rem;
  }
}

.trainers-heading {
  color: #183b56;
  font-family: sofia-pro, sans-serif;
  font-weight: bold;
  line-height: 65px;
  font-size: 38px;
  font-style: normal;
  margin: 0px;
}

@media screen and (max-width: 767px) {
  .trainers-heading {
    font-size: 22px;
    line-height: 38px;
  }
}

.tainers-image-container {
  margin-top: 3rem;
  height: auto;
  width: auto;
  background-color: #f8fafc;
  border: 1px solid #cbd5e1;
  border-radius: 16px;
  box-shadow: 0 1px 1px rgba(96, 97, 112);
  padding: 1rem 4rem;
  display: flex;
  justify-content: space-around;
}

@media screen and (max-width: 767px) {
  .tainers-image-container {
    flex-direction: column;
    padding: 0.3rem;
  }
}

.traine-category-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.traine-category-heading {
  font-family: sofia-pro, sans-serif;
  /* letter-spacing: 1px; */
  color: #304a5e;
  font-weight: 500;
  font-size: 16px;
}

.trainee-type {
  color: #183b56;
  font-weight: 600;
}

.divid-two-traines-wise {
  display: flex;
}

.each-tainee-container {
  width: 160px;
  background-color: #fff;
  border: 1px solid #cbd5e1;
  border-radius: 12px;
  box-shadow: 0 1px 1px rgb(208, 208, 212);
  text-align: center;
  margin: 0.5rem;
  padding: 5px;
}

.traine-image {
  width: 150px;
  height: 150px;
  object-fit: contain;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.traine-name {
  font-size: 14px;
  color: #183b56;
  font-family: sofia-pro, sans-serif;
  margin: 3px;
  font-weight: 600;
}

.traine-company {
  margin: 0px;
  margin-bottom: 5px;
  font-size: 12px;
  color: #333;
}

.taine-student-container {
  background-color: #fff;
  border: 1px solid #cbd5e1;
  border-radius: 12px;
  box-shadow: 0 1px 1px rgb(208, 208, 212);
  width: 252px;
  height: 350px;
  text-align: center;
  align-self: center;
}

.traine-student-image {
  width: 250px;
  height: 280px;
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
}

.student-at {
  font-size: 20px;
  font-weight: 400;
  margin: 0px;
}

.student-at-career-sure-academy {
  font-size: 14px;
  font-weight: 700;
  margin-top: 8px;
  margin-bottom: 0px;
  color: #ffa505;
}

@media screen and (max-width: 767px) {
  .taine-student-container {
    display: none;
  }
}

.and-more {
  color: #2f3e49;
  font-weight: 500;
}

.traine-callback-btn {
  align-self: center;
  margin-top: 5rem;
  margin-bottom: 2rem;
}

@media screen and (max-width: 768px) {
  .traine-callback-btn {
    margin-top: 3rem;
  }
}

.traine-request-btn {
  background-color: #ffd301;
  border: none;
  height: 48px;
  /* width: 100%; */
  border-radius: 8px;
  color: #000;
  font-weight: 600;
  letter-spacing: 1px;
  font-family: sofia-pro, sans-serif;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.calling-icon {
  margin-right: 8px;
}