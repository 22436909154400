.main-home-container {
  display: flex;
  justify-content: center;
  /* align-items: center; */
  padding: 0.5rem 3rem;
  background-color: #f8fafc;
}

@media screen and (max-width: 767px) {
  .main-home-container {
    display: flex;
    flex-direction: column;
  }
}

.caption-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 3rem;
  margin-right: 1.5rem;
}

.company-image {
  border-radius: 10%;
  width: 250px;
  margin-top: 35px;
}

.home-heading {
  color: #183b56;
  font-family: sofia-pro, sans-serif;
  font-weight: bold;
  text-align: center;
  line-height: 48px;
  font-size: 32px;
  font-style: normal;
  margin: 0px;
  animation: fadeIn 1s infinite alternate;
}

@media screen and (max-width: 767px) {
  .home-heading {
    font-size: 24px;
    line-height: 36px;
  }

  .company-image {
    display: none;
  }
}

@keyframes fadeIn {
  0% {
    color: #183b56;
    /* opacity: 0.7; */
  }

  50% {
    color: green;
  }

  100% {
    color: #1565d8;
  }
}

.home-heading-academy-name {
  color: #ffa505;
}

.home-description {
  color: #183b56;
  font-size: 22px;
  font-family: sofia-pro, sans-serif;
  line-height: 30px;
  font-weight: 600;
  text-align: center;
  margin: 3rem;
}

.quatestion {
  font-size: 20px;
  margin-left: 5rem;
}

.cards-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;
}

@media screen and (max-width: 767px) {
  .cards-container {
    display: flex;
    flex-direction: column;
  }
}

.course-detailed-box {
  width: auto;
  height: auto;
  min-height: 470px;
  margin-bottom: 48px;
  margin-right: 0;
  width: 340px;
  /* height: 580px; */
  background-color: #fff;
  border: 1px solid #cbd5e1;
  border-radius: 16px;
  margin-right: 24px;
  box-shadow: 0 1px 2px rgba(96, 97, 112);
  padding: 1rem;
  background: url(https://media.istockphoto.com/id/1152126136/vector/decorative-border-and-frame-template-in-square-shape-vintage-frame-design-for-certificate.jpg?s=612x612&w=0&k=20&c=BlHjBPmiF9Z0E6FGmDAjxJE7wzGj-H-5HO80DFBAyRY=);
  background-repeat: no-repeat;
  background-size: cover;
}

.course-details-image-container {
  display: flex;
  justify-content: space-between;
}

.academy-heading {
  color: #cd66d1;
  text-shadow: 1px 2px 3px #000;
  text-align: center;
  font-size: 42px;
  font-family: "Inter 600", sans-serif;
  padding: 2px 3px;
  /* background-color: #413d3d; */
  background-color: #f9f0ca;
  align-self: flex-start;
  border-radius: 8px;
  margin: 0;
}

.card-lpa-text {
  text-align: center;
  color: #ffa505;
  font-weight: 800;
  font-size: 30px;
}

.academy-person-image {
  width: 149px;
  height: 218px;
  display: block;
  top: -66px;
  position: relative;
}

.eligibility-creteria-container {
  justify-self: flex-end;
  top: -80px;
  position: relative;
}

.intensive-eligibility-type {
  display: flex;
}

.academy-type {
  background-color: #8d95da;
  width: 70%;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1px;
  font-family: sofia-pro, sans-serif;
  padding: 3px;
  border-radius: 3rem;
  text-align: center;
  color: rgb(2, 37, 4);
  margin-bottom: 0px;
}

.academy-year {
  background-color: rgb(9, 231, 9);
  width: 60%;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1px;
  font-family: sofia-pro, sans-serif;
  padding: 3px;
  border-radius: 3rem;
  text-align: center;
  color: rgb(6, 29, 6);
  margin-bottom: 0px;
}

.academy-description-container {
  top: -85px;
  position: relative;
  background-color: #fff;
  box-shadow: 0px -10px 30px 10px #fff;
}

.academy-description {
  color: #183b56;
  font-family: sofia-pro, sans-serif;
  letter-spacing: 1px;
  line-height: 30px;
}

.batches-seats-container {
  top: -60px;
  position: relative;
  display: flex;
  justify-content: space-between;
}

.batch {
  color: #413d3d;
  font-weight: 600;
  font-size: 13px;
  font-family: sofia-pro, sans-serif;
  letter-spacing: 1px;
}

.day-name {
  color: #183b56;
  font-weight: 700;
}

.seats {
  color: #413d3d;
  font-weight: 600;
  font-size: 13px;
  font-family: sofia-pro, sans-serif;
  letter-spacing: 1px;
}

.availability {
  color: #413d3d;
  font-weight: 600;
  font-size: 13px;
  font-family: sofia-pro, sans-serif;
  letter-spacing: 1px;
  margin-left: 20px;
}

.callback-btn-container {
  top: -40px;
  position: relative;
}

.request-btn {
  /* background-color: #1565d8; */
  background-color: #ffd500;
  border: none;
  height: 48px;
  width: 100%;
  border-radius: 8px;
  color: #000;
  font-weight: 600;
  letter-spacing: 1px;
  font-family: sofia-pro, sans-serif;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.calling-icon {
  margin-right: 8px;
}

.know-more-container {
  top: -30px;
  position: relative;
  text-align: center;
}

.graduates {
  color: rgb(2, 54, 2);
  background-color: rgb(141, 176, 199);
  margin-right: 1rem;
  width: 35%;
}

.final-year {
  width: 35%;
}

.any-batch {
  background-color: rgb(179, 255, 1);
  color: rgb(109, 5, 36);
  width: 60%;
  text-align: center;
}

@media screen and (min-width: 867px) {
  .mobile-device-slider {
    display: none;
  }
}

@media screen and (max-width: 866px) {
  .large-device-slider {
    display: none;
  }
}

.whatsapp-btn {
  text-decoration: none;
  color: #000;
}

/* Animation styles */
/* .fade-out-text span {
  opacity: 1;
  display: inline-block;
  transition: opacity 0.5s ease-in-out;
}

.fade-out-text span::after {
  content: " ";
  display: inline-block;
  width: 0;
  height: 100%;
  margin-left: 5px; 
  background: #000;
  transition: width 0.5s ease-in-out;
} */