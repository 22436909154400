.mobile-device-testinominal-container {
  margin: 3rem;
  /* background-color: #333; */
  padding: 2rem;
}
@media screen and (max-width: 767px) {
  .mobile-device-testinominal-container {
    margin: 1rem;
  }
}
.mobile-device-each-testinominal {
  text-align: center;
  padding: 2rem;
}
.mobile-device-quote-and-review {
  display: flex;
  /* justify-content: flex-start; */
  align-items: flex-start;
}
.mobile-device-quote-logo {
  width: 20px;
}
.mobile-device-stundent-review {
  height: auto;
  color: #183b56;
  font-family: sofia-pro, sans-serif;
  font-weight: 400;
  text-align: center;
  letter-spacing: 1px;
  line-height: 22px;
  font-size: 12px;
  font-style: normal;
  margin: 0px;
  min-height: 180px;
  height: auto;
}

.mobile-device-name-and-comapany-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
  /* border: 1px solid red; */
  margin: 1.5rem 0rem;
}
.mobile-device-student-name {
  margin: 0px;
  padding: 0px;
  border-right: 1px solid rgb(126, 122, 122);
  padding-right: 1rem;
  color: #183b56;
  font-size: 15px;
}
.mobile-device-company-logo {
  width: 100px;
  margin: 0px;
  padding: 0px;
  margin-left: 1rem;
}
.mobile-device-stundent-image {
  width: 156px;
  /* height: 156px; */
  border-radius: 50%;
  margin: auto;
  background-color: #ffd301;
}
.slick-prev,
.slick-next {
  background-color: #183b56;
  border-radius: 50%;
}
.slick-prev,
.slick-next:hover {
  background-color: #183b56;
}
.slick-prev,
.slick-next:active {
  background-color: #183b56;
}
.slick-prev::before {
  background-color: #183b56;
  border-radius: 50%;
}
.slick-next::after {
  background-color: #183b56;
}
