.nav-header {
  display: flex;
  justify-content: center;
  background-color: #f8fafc;
  /* background-color: rgb(17, 8, 83); */
  border-bottom: 1px solid rgb(243, 243, 243);
}

@media screen and (max-width: 768px) {
  .nav-header {
    flex-direction: column;
    align-items: center;
    border-bottom-style: none;
  }
}

.nav-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1110px;
  padding-bottom: 10px;
}
@media screen and (max-width: 768px) {
  .nav-content {
    flex-direction: column;
    width: 90%;
  }
}

.nav-bar-mobile-logo-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  /* border: 1px solid red; */
}

@media screen and (min-width: 768px) {
  .nav-bar-mobile-logo-container {
    display: none;
  }
}

.nav-bar-large-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1110px;
  /* border: 1px solid red; */
}

@media screen and (max-width: 767px) {
  .nav-bar-large-container {
    display: none;
  }
}

.website-logo {
  width: 45%;
  height: auto;
  margin-top: 5px;
  border-radius: 10%;
}

@media screen and (min-width: 768px) {
  .website-logo {
    width: 60%;
    height: 82%;
  }
}

.nav-menu {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-self: center;
  flex: 1;
  list-style-type: none;
  margin-top: 0px;
  margin-bottom: 0px;
}

@media screen and (max-width: 767px) {
  .nav-menu {
    display: none;
  }
}
.nav-menu-mobile {
  width: 90%;
}

@media screen and (min-width: 768px) {
  .nav-menu-mobile {
    display: none;
  }
}

.nav-menu-list-mobile {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex: 1;
  list-style-type: none;
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 0px;
}

.nav-menu-item {
  font-family: "Roboto";
  text-decoration: none;
  margin: 10px;
}

.nav-link {
  text-decoration: none;
  color: #333;
  /* color: #fff; */
  font-size: 18px;
  font-weight: 600;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  cursor: pointer;
}
.active {
  color: #ffa505;
}

/* .home-link:active {
  color: #0082f3;
} */
.nav-link:hover {
  color: #0082f3;
}

@media screen and (max-width: 767px) {
  .nav-link {
    font-size: 14px;
  }
}

.nav-menu-item-mobile {
  padding: 5px;
  cursor: pointer;
}

/* .nav-mobile-btn-container {
  display: flex;
  justify-content: center;
  width: 25%;
} */
.mobile-nav-bar-btn-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
}
.nav-mobile-btn {
  border: none;
  padding: 0px;
  background: transparent;
  cursor: pointer;
  outline: none;
  color: #333;
  font-size: 20px;
  margin-right: 2rem;
}
.join-now-btn {
  background: none;
  border: none;
}
@media screen and (min-width: 768px) {
  .nav-mobile-btn {
    display: none;
  }
  .join-now-btn {
    display: none;
  }
}
.nav-bar-image {
  width: 24px;
}
