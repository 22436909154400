.about-us-container {
  margin: 3.5rem;
}
@media screen and (max-width: 768px) {
  .about-us-container {
    margin: 1.5rem;
  }
}
.about-us-main-heading {
  color: #183b56;
  font-family: sofia-pro, sans-serif;
  font-weight: 700;
  line-height: 48px;
  font-size: 32px;
  font-style: normal;
  display: inline;
  padding: 1rem;
  background-color: #ffd301;
  border-top-right-radius: 90%;
  border-bottom-right-radius: 80%;
}

@media screen and (max-width: 768px) {
  .about-us-main-heading {
    font-size: 24px;
    padding: 0.5rem;
    margin: 0px;
  }
}
.about-us-content {
  /* color: #183b56; */
  color: #1565d8;
  font-family: sofia-pro, sans-serif;
  font-weight: 700;
  line-height: 48px;
  font-size: 24px;
  font-style: normal;
}
@media screen and (max-width: 768px) {
  .about-us-content {
    font-size: 18px;
    line-height: 34px;
  }
}
.about-us-company-name {
  color: #ffa505;
  font-size: 25px;
}
@media screen and (max-width: 768px) {
  .about-us-company-name {
    font-size: 24px;
  }
}
.about-us-description-container {
  display: flex;
}
.aboutus-arrow-icon {
  font-size: 100px;
  color: #183b56;
  margin-top: -1.7rem;
  margin-right: 1.5rem;
}
@media screen and (max-width: 768px) {
  .arrow-icon {
    font-size: 16px;
    margin-top: 0.4rem;
  }
}
.about-us-description {
  color: #183b56;
  font-family: sofia-pro, sans-serif;
  font-weight: 700;
  line-height: 48px;
  text-align: justify;
  font-size: 18px;
  font-style: normal;
  margin: 0px;
  padding: 0px;
}
@media screen and (max-width: 768px) {
  .about-us-description {
    font-size: 14px;
    line-height: 28px;
  }
}
